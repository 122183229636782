<template>
  <section class="board-view">
    <header>
      <h1>{{ board ? board.name : 'Loading board...' }}</h1>
      <AppMainMenu :board="board" @showAdmin="showingAdmin = true"></AppMainMenu>
    </header>
    <main>
      <BoardItemList
        title="Upcoming"
        :items="futureItems"
        @open-item="openItem"
      ></BoardItemList>
      <BoardItemList
        ref="currentListEl"
        title="Current"
        :items="currentItems"
        @open-item="openItem"
      ></BoardItemList>
      <BoardItemList
        title="Done"
        :items="doneItems"
        @open-item="openItem"
      ></BoardItemList>
    </main>
    <footer>
      <button v-on:click="openItem()">New Blank Item</button>
      <button v-on:click="showTemplateMenu = 'add'">Add From Template</button>
      <button v-on:click="showLeaderboard">Show Today's Totals</button>
    </footer>

    <BoardItemDetail
      v-if="detailItem"
      :categories="categories"
      :doers="doers"
      :tags="tags"
      :tagColors="tagColors"
      :item="detailItem"
      @add-task="addTask"
      @delete-task="deleteTask"
      @clone-task="cloneTask"
      @save="saveItem"
      @delete="deleteItem"
      @close="closeItem"
    ></BoardItemDetail>
    <BoardTemplateMenu
      v-if="showTemplateMenu"
      :action="showTemplateMenu"
      :categories="categories"
      :templates="templatesByCategory"
      @add="addFromTemplate"
      @close="showTemplateMenu = null"
      @copy="copyTemplate"
      @edit="editTemplate"
    ></BoardTemplateMenu>
    <BoardLeaderboard
      v-if="leaderboard"
      :pairs="leaderboard"
      @close="leaderboard = null"
    ></BoardLeaderboard>
    <BoardAdminMenu
      v-if="showingAdmin"
      :templates="templates"
      :categories="categories"
      :tags="tags"
      :doers="doers"
      @action="adminAction"
      @close="showingAdmin = false"
      @editTemplates="editTemplates"
    ></BoardAdminMenu>
    <BoardDisconnectAlert
      v-if="disconnected"
      v-on:reload="reload"
    ></BoardDisconnectAlert>
  </section>
</template>

<script setup>
  import {computed, inject, onBeforeUnmount, onMounted, ref, shallowRef, watch} from 'vue';

  import AppMainMenu from '../components/AppMainMenu';
  import BoardAdminMenu from '../components/BoardAdminMenu';
  import BoardDisconnectAlert from '../components/BoardDisconnectAlert.vue';
  import BoardItemDetail from '../components/BoardItemDetail.vue';
  import BoardItemList from '../components/BoardItemList.vue';
  import BoardLeaderboard from '../components/BoardLeaderboard.vue';
  import BoardTemplateMenu from '../components/BoardTemplateMenu.vue';

  import {useRoute, useRouter} from 'vue-router';

  const setContext = inject('setContext');

  const route = useRoute();
  const router = useRouter();
  const boardId = route.params.boardId;

  const endOfDay = ref(new Date(Date.now() + 24 * 60 * 60 * 1000)); // one day from now
  endOfDay.value.setHours(0, 0, 0, 0); // midnight tonight
  function getLocalDateString (date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${ year }-${ month }-${ day }`;
  }

  // items
  const items = ref({});
  const futureItems = ref([]);
  const currentItems = ref([]);
  const doneItems = ref([]);
  const detailItem = ref(null);
  const showTemplateMenu = ref(false);
  function setUpItem (item) {
    item._d = new Date(item.datetime);
    item.date = item.datetime.substring(0, 10);
    item.time = item.datetime.substring(11, 19);
    item.units = item.tasks.reduce((acc, cur) => acc + cur.units, 0);
    const itemDoers = new Set();
    const itemTagColors = new Set();
    item.completedTasks = 0;
    for (const task of item.tasks) {
      task.doer = doers.value[task.doer_id];
      if (task.doer) {
        itemDoers.add(task.doer);
      }
      task.tags.forEach(tag => {
        // console.debug('tag', tag, this.tagColors[tag]);
        if (tagColors.value[tag]) {
          itemTagColors.add(tagColors.value[tag]);
        }
      });
      if (task.completed) {
        item.completedTasks += 1;
      }
    }
    item.doers = Array.from(itemDoers.values());
    item.tagColors = itemTagColors;
    item.completed = item.tasks.length ? item.tasks.every(t => t.completed) : !!item.completed;
    // console.debug('set up item', item);
    return item;
  }
  function addItem (item) {
    console.debug('adding/updating item', item);
    setUpItem(item);
    items.value[item.id] = item;
    sortItems();
  }
  function sortItems () {
    // sort by future, current, done
    const fi = [], ci = [], di = [];
    for (const item of Object.values(items.value)) {
      if (item.completed) {
        di.push(item);
      } else {
        if (item._d < endOfDay.value) {
          ci.push(item);
        } else {
          fi.push(item);
        }
      }
    }

    // each list in ascending order
    // might be better to sort first?
    const sort = (a, b) => a._d - b._d;
    fi.sort(sort);
    ci.sort(sort);
    di.sort(sort);

    // add date labels between items in upcoming list
    let idx = 0, lastDate = null;
    while (idx < fi.length) {
      const itemDate = getLocalDateString(fi[idx]._d);
      if (itemDate !== lastDate) {
        fi.splice(idx, 0, {label: itemDate});
        lastDate = itemDate;
        idx++;
      }
      idx++;
    }

    // add date/time labels between items in current list
    const curDate = getLocalDateString(new Date());
    idx = 0;
    lastDate = null;
    while (idx < ci.length) {
      const itemDate = getLocalDateString(ci[idx]._d);
      if (itemDate === curDate) {
        break;
      }
      if (itemDate !== lastDate) {
        ci.splice(idx, 0, {label: itemDate});
        lastDate = itemDate;
        idx++;
      }
      idx++;
    }
    if (idx < ci.length) {
      ci.splice(idx, 0, {label: 'Today'});
      idx++;
    }
    let lastHour = null;
    while (idx < ci.length) {
      const itemHour = ci[idx]._d.getHours();
      if (itemHour !== lastHour) {
        ci.splice(idx, 0, {
          label: itemHour.toString().padStart(2, '0') + ':00',
        });
        lastHour = itemHour;
        idx++;
      }
      idx++;
    }

    // add date labels between items in done list
    idx = 0;
    lastDate = null;
    while (idx < di.length) {
      const itemDate = getLocalDateString(di[idx]._d);
      if (itemDate !== lastDate) {
        di.splice(idx, 0, {label: itemDate});
        lastDate = itemDate;
        idx++;
      }
      idx++;
    }

    futureItems.value = fi;
    currentItems.value = ci;
    doneItems.value = di;
  }
  function addFromTemplate (templateId) {
    socket.value.send(JSON.stringify({
      action: 'add-from-template',
      template: templateId,
    }));
  }
  function openItem (item) {
    if (item) {
      detailItem.value = JSON.parse(JSON.stringify(item));
    } else {
      let itemDate = new Date();
      if (itemDate.getHours() >= 21) {
        itemDate = new Date(Date.now() + 24 * 60 * 60 * 1000);
      }
      const dateStr = itemDate.getFullYear() + '-' +
        (itemDate.getMonth() + 1).toString().padStart(2, '0') + '-' +
        itemDate.getDate().toString().padStart(2, '0');
      detailItem.value = {
        date: dateStr,
        time: '23:00',
        category: null,
        tasks: [{units: 1, tags: []}],
      };
    }
    console.debug('detailItem', detailItem.value);
  }
  function deleteItem () {
    const type = detailItem.value.isTemplate ? 'template' : 'item';
    if (window.confirm(`Delete this ${ type }?`)) {
      socket.value.send(JSON.stringify({
        action: 'delete-' + type,
        [type]: detailItem.value.id,
      }));
      closeItem();
    }
  }
  function closeItem () {
    detailItem.value = null;
  }
  function saveItem () {
    if (detailItem.value.isTemplate) {
      delete detailItem.value.isTemplate;
      saveTemplate(detailItem.value);
      return;
    }
    for (const task of detailItem.value.tasks) {
      if (task.completed && task.units && !task.doer) {
        alert('All completed tasks must be assigned.');
        return;
      }
    }
    detailItem.value.datetime = detailItem.value.date + 'T' +
      detailItem.value.time;
    // these aren't part of the model on the backend
    delete detailItem.value.date;
    delete detailItem.value.time;
    delete detailItem.value.units;
    delete detailItem.value.doers;
    for (const task of detailItem.value.tasks) {
      // set doer id and remove the attached object
      if (task.doer) {
        task.doer_id = task.doer.id;
      } else {
        task.doer_id = undefined;
      }
      delete task.doer;
    }
    // don't try to send an update for timestamps
    delete detailItem.value.added;
    delete detailItem.value.completed;
    console.debug('saving item', detailItem.value);
    socket.value.send(JSON.stringify({
      action: 'save-item',
      item: detailItem.value,
    }));
    detailItem.value = null;
  }

  // tasks
  function addTask () {
    detailItem.value.tasks.push({units: 1, tags: []});
  }
  function deleteTask (idx) {
    detailItem.value.tasks.splice(idx, 1);
  }
  function cloneTask (idx) {
    // console.debug('cloneTask', detailItem.value.tasks[idx]);
    const original = detailItem.value.tasks[idx];
    const clone = Object.assign({}, original);
    clone.tags = original.tags.slice();
    if (original.doer_id !== undefined) clone.doer_id = null;
    clone.doer = undefined;
    detailItem.value.tasks.splice(idx + 1, 0, clone);
  }

  // admin
  const showingAdmin = ref(false);
  function adminAction (action) {
    console.log('adminAction', action);
    const msgAction = action.name === 'delete' ? 'delete' : 'save';
    const msgType = `${ msgAction }-${ action.type }`;
    sendSocketMesssage(msgType, {[action.type]: action.arg});
  }
  function editTemplates () {
    showingAdmin.value = false;
    showTemplateMenu.value = 'edit';
  }
  const copyingTemplate = ref(false);
  function copyTemplate (templateId) {
    // console.debug('copyTemplate', templateId);
    copyingTemplate.value = true;
    socket.value.send(JSON.stringify({
      action: 'edit-template',
      template: templateId,
    }));
  }
  function editTemplate (templateId) {
    if (templateId) {
      socket.value.send(JSON.stringify({
        action: 'edit-template',
        template: templateId,
      }));
    } else {
      detailItem.value = {
        category: null,
        tasks: [{units: 1, tags: []}],
        time: '23:00',
        isTemplate: true,
      };
    }
  }
  function saveTemplate (template) {
    // console.debug('saveTemplate', template);
    socket.value.send(JSON.stringify({
      action: 'save-template',
      template,
    }));
    closeItem();
  }

  // connection
  const socket = shallowRef(null);
  const pingSuccess = ref(true);
  const disconnected = ref(false);
  function toObjById (list) {
    const obj = {};
    list.forEach((item) => {
      obj[item.id] = item;
    });
    return obj;
  }
  function processMessage (message) {
    switch (message.type) {
    case 'initial': {
      console.debug('initial', message);
      board.value = message.board;
      doers.value = toObjById(message.doers);
      categories.value = toObjById(message.categories);
      tags.value = toObjById(message.tags);
      for (const tag of Object.values(tags.value)) {
        tagColors.value[tag.id] = tag.color;
      }
      templates.value = toObjById(message.templates);
      const msgItems = {};
      for (const item of message.items) {
        setUpItem(item);
        msgItems[item.id] = item;
      }
      items.value = msgItems;
      // console.debug('items', msgItems);
      setupPing();
      break;
    }
    case 'item-update':
      addItem(message.item);
      break;
    case 'item-delete':
      delete items.value[message.item];
      sortItems();
      break;
    case 'category-update':
      categories.value[message.category.id] = message.category;
      break;
    case 'category-delete':
      delete categories.value[message.category];
      break;
    case 'tag-update':
      tags.value[message.tag.id] = message.tag;
      break;
    case 'tag-delete':
      delete tags.value[message.tag];
      break;
    case 'doer-update':
      doers.value[message.doer.id] = message.doer;
      break;
    case 'doer-delete':
      delete doers.value[message.doer];
      break;
    case 'template-edit':
      // console.log('template-edit', message);
      message.template.isTemplate = true;
      if (copyingTemplate.value) {
        message.template.id = undefined;
        message.template.title = message.template.title + ' (copy)';
        copyingTemplate.value = false;
      }
      detailItem.value = message.template;
      break;
    case 'template-update':
      templates.value[message.template.id] = message.template;
      break;
    case 'template-delete':
      delete templates.value[message.template];
      break;
    case 'pong':
      pingSuccess.value = true;
      break;
    default:
      console.warn('no handler for message', message);
    }
  }
  function sendSocketMesssage (type, data) {
    const message = Object.assign({action: type}, data || {});
    socket.value.send(JSON.stringify(message));
  }
  function setupPing () {
    window.setInterval(function () {
      pingSuccess.value = false;
      sendSocketMesssage('ping');
      window.setTimeout(function () {
        if (!pingSuccess.value) {
          showDisconnectAlert();
        }
      }, 5000);
    }, 30000);
  }
  function showDisconnectAlert () {
    disconnected.value = true;
  }
  function reload () {
    window.location.reload();
  }

  // leaderboard
  const leaderboard = ref(null);
  function showLeaderboard () {
    const totals = {};
    for (const item of doneItems.value) {
      if (item.label) {
        // this is just a date/time divider
        continue;
      }
      for (const task of item.tasks) {
        if (!(task.doer && task.units)) {
          continue;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (!totals.hasOwnProperty(task.doer.name)) {
          totals[task.doer.name] = 0;
        }
        totals[task.doer.name] += task.units;
      }
    }
    const pairs = Object.entries(totals);
    pairs.sort((a, b) => b[1] - a[1]);
    leaderboard.value = pairs;
  }

  // initialization
  const currentListEl = ref(null);
  const board = ref(null);
  const categories = ref({});
  const doers = ref({});
  const tags = ref({});
  const tagColors = ref({});
  const templates = ref({});
  const templatesByCategory = computed(() => {
    const byCat = {'': []};
    Object.keys(categories.value).forEach((categoryId) => {
      byCat[categoryId] = [];
    });
    for (const temp of Object.values(templates.value)) {
      byCat[temp.category || ''].push(temp);
    }
    return byCat;
  });
  function initializeBoard () {
    board.value = null;
    doers.value = {};
    categories.value = {};
    tags.value = {};
    tagColors.value = {};
    templates.value = {};
    currentListEl.value.container.scrollIntoView({block: 'end', inline: 'center'});

    const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
    const SOCKET_URL = wsProtocol + '//' + window.location.host + '/ws/boards/' + boardId;
    socket.value = new WebSocket(SOCKET_URL);
    socket.value.addEventListener('message', e => {
      const message = JSON.parse(e.data);
      // console.debug('received message', message);
      processMessage(message);
    });
    socket.value.addEventListener('close', e => {
      if (e.code === 4100) {
        window.location = '/account/login/';
        return;
      } else if (e.code === 4300) {
        router.push('/boards');
        return;
      }
      console.error('socket closed unexpectedly', e);
      showDisconnectAlert();
    });
  }
  watch(() => route.params, (to, from) => {
    if (to.boardId !== from.boardId) {
      socket.value.close();
      initializeBoard();
    }
  });
  onMounted(() => {
    window.setTimeout(
      rollOverDay,
      endOfDay.value.getTime() - Date.now() + 5000,
    );
    initializeBoard();
  });
  onBeforeUnmount(() => {
    socket.value.close();
  });
  function rollOverDay () {
    Object.keys(items.value).forEach(itemId => {
      const item = items.value[itemId];
      if (item.completed) {
        const completedDate = new Date(item.completed);
        if (completedDate < endOfDay.value) {
          delete items.value[itemId];
        }
      }
    });
    const now = Date.now();
    endOfDay.value = new Date(now + 24 * 60 * 60 * 1000); // 24 hours
    endOfDay.value.setHours(0, 0, 0, 0); // midnight tonight
    sortItems();
    window.setTimeout(
      rollOverDay,
      endOfDay.value.getTime() - now + 5000,
    );
  }
  watch(board, (board) => {
    setContext({board});
  });
  watch(items, () => {
    sortItems();
  });
</script>

<style>
  .board-view {
    display: flex;
    flex-direction: column;
  }
  .board-view main {
    display: flex;
    flex: calc(100vh - 9em);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .board-view main > section {
    display: flex;
    flex: 1 1 33%;
    flex-direction: column;
    min-width: 17em;
    margin: 0.1em 1em 0.5em;
    padding: 1em;
    background: rgb(175 105 50);
    border-radius: 5px;
    scroll-snap-align: center;
  }
  .board-view main > section + section {
    margin-left: 0;
  }
  .board-view section > h2 {
    text-align: center;
  }

  .board-view > dialog {
    padding: 0;

    border: 0;
    border-radius: 5px;
    background: tan;
  }
  .board-view > dialog form {
    padding: 1em;
  }
  .board-view > dialog h1 {
    margin: 0;
  }
  .board-view > dialog .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.2em;
    padding: 0.1em 0.3em;
    font-size: large;
    cursor: pointer;
    background: rgb(255 255 255 / 70%);
    border-radius: 0.2em;
  }

  .board-view > footer {
    display: flex;
    justify-content: space-around;
  }
  .board-view > footer > * {
    flex-basis: 30%;
  }
  .board-view > footer .status {
    padding: 1em 0;
    text-align: center;
  }
  .board-view > footer .status.error {
    padding: 0;
  }
  .board-view > footer .status button {
    margin-left: 1em;
  }
</style>
