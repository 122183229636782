<template>
  <AppModalDialog
    class="template-menu"
    :class="{copying}"
    @close="$emit('close')"
  >
    <header>
      <h2>{{ action === 'add' ? 'Add Items' : 'Edit Templates' }}</h2>
      <button
        v-if="action === 'edit'"
        class="new-template-button"
        :disabled="copying"
        @click="$emit('edit')"
      >New</button>
      <button
        v-if="action === 'edit'"
        class="copy-template-button"
        @click="doCopy"
      >{{ copying ? 'Cancel' : 'Copy' }}</button>
    </header>
    <ul>
      <li v-for="(categoryTemplates, categoryId) in templates" :key="categoryId">
        <h3>{{ categoryId ? categories[categoryId].name : 'General' }}</h3>
        <ul>
          <li v-for="template in categoryTemplates" :key="template.id">
            <button @click="clickedTemplate(template)">{{ template.title }}</button>
          </li>
        </ul>
      </li>
    </ul>
  </AppModalDialog>
</template>

<script setup>
  import {ref, toRefs} from 'vue';
  import AppModalDialog from './AppModalDialog';

  const props = defineProps({
    action: String,
    categories: Object,
    templates: Object,
  });
  const {action} = toRefs(props);
  const emit = defineEmits(['add', 'close', 'copy', 'edit']);

  const copying = ref(false);
  function doCopy () {
    copying.value = !copying.value;
  }
  function clickedTemplate (template) {
    if (copying.value) {
      copying.value = false;
      emit('copy', template.id);
    } else {
      emit(action.value, template.id);
    }
  }
</script>

<style>
  .template-menu.copying {
    cursor: not-allowed;
  }
  .template-menu button {
    width: 100%;
    padding: 0.5em 1em;
    background: rgb(255 255 255 / 80%);
    color: rgb(0 0 0 / 80%);
    border-radius: 0.5em;
  }
  .template-menu .close-button {
    position: absolute;
    width: 1em;
  }
  .template-menu.modal-dialog > header {
    padding-right: 3em;
  }
  .template-menu.modal-dialog > header button {
    margin-top: 0;
    flex: 100%;
    background: rgb(255 255 255 / 80%);
    border: 1px solid;
  }
  .template-menu > header button:disabled {
    color: rgb(0 0 0 / 50%);
    cursor: not-allowed;
  }
  .template-menu > header .copy-template-button {
    cursor: pointer;
  }
  .template-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
  }
  .template-menu > ul {
    display: flex;
    overflow-x: auto;
  }
  .template-menu > ul > li {
    margin: 0.5em;
    min-width: 10em;
    vertical-align: top;
    background: rgb(54 27 10);
    color: rgb(255 255 255 / 80%);
    border-radius: 0.5em;
  }
  .template-menu > ul > li + li {
    margin-left: 0;
  }
  .template-menu h3 {
    margin: 0.5em;
  }
  .template-menu ul ul {
    max-height: calc(90vh - 5em);
    padding: 0 0.5em;

    overflow-y: auto;
  }
</style>
