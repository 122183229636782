<template>
  <button class="menu-button fas fa-bars" @click="showingMenu = true"></button>
  <AppModalDialog
    v-if="showingMenu"
    class="main-menu"
    :noClose="true"
    @close="showingMenu = false"
  >
    <nav><ul>
      <li><button
        @click="go(`archive/${ board.id }/`)"
        :disabled="!board"
      >Archive</button></li>
      <li><button @click="openAdmin" :disabled="!board">Admin</button></li>
      <li><button @click="goToBoards" :disabled="!board">Boards</button></li>
      <li><button @click="logOut">Log Out</button></li>
    </ul></nav>
  </AppModalDialog>
</template>

<script setup>
  import {ref} from 'vue';
  import AppModalDialog from '@/components/AppModalDialog';
  import {useRouter} from 'vue-router';

  defineProps({board: Object});
  const emit = defineEmits(['showAdmin']);
  const router = useRouter();

  const showingMenu = ref(false);
  function go (to) {
    window.location = '/' + to;
  }
  function openAdmin () {
    emit('showAdmin');
    showingMenu.value = false;
  }
  function goToBoards () {
    router.push('/');
    showingMenu.value = false;
  }
  function logOut () {
    if (window.confirm('Log out?')) window.location = '/account/logout/';
  }
</script>

<style>
  .menu-button {
    position: absolute;
    right: 0.7em;
    top: 0.7em;
    width: 2.5em;

    border: 0;
    border-radius: 0.5em;
    cursor: pointer;
  }
  .main-menu {
    padding: 0;
    bottom: unset;
    left: unset;
    right: 0.5em;
    top: 0.5em;

    background: rgb(255 255 255 / 0.9);
    border: 0;
    border-radius: 0.2em;
  }
  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0.1em;
  }
  .main-menu li {
    padding: 0;
    margin: 0;
  }
  .main-menu li + li {
    margin-top: 0.1em;
  }
  .main-menu li button {
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
  .main-menu li button:disabled {
    opacity: 0.5;
    cursor: default;
  }
</style>
