<template>
  <transition appear
    @after-enter="open"
    @before-leave="close"
    :css="false"
  >
    <dialog
      class="modal-dialog"
      :class="{'with-header': header}"
      @click.self="$emit('close')"
      @close="$emit('close')"
    >
      <header v-if="header">
        <h2>{{ header }}</h2>
        <button v-if="!noClose" class="fas fa-times" @click="$emit('close')"></button>
      </header>
      <button v-if="!header && !noClose" class="close-button fas fa-times" @click="$emit('close')"></button>
      <slot></slot>
    </dialog>
  </transition>
</template>

<script setup>
  defineProps({header: String, noClose: Boolean});
  defineEmits(['close']);

  function open (el) {
    // console.debug('open', el);
    el.showModal();
  }
  function close (el) {
    // console.debug('close', el);
    el.close();
  }
</script>

<style>
  dialog.modal-dialog {
  }
  dialog.modal-dialog.with-header {
    min-width: 15em;
  }
  dialog.modal-dialog::backdrop {
    background: rgb(0 0 0 / 50%);
  }
  dialog.modal-dialog > header {
    display: flex;
    padding: 0.5em 0.5em 0;
  }
  dialog.modal-dialog > header h2 {
    flex: 100%;
    margin: 0 auto;
  }
  dialog.modal-dialog > header button, dialog.modal-dialog > button.close-button {
    margin-left: 0.5em;
    min-height: 0;
    padding: 0;
    width: 1em;

    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: x-large;
  }
</style>
