<template>
  <section class="board-list">
    <header>
      <h1>My Boards</h1>
      <AppMainMenu></AppMainMenu>
    </header>
    <p v-if="!boards.length">No boards!</p>
    <ul v-if="boards.length">
      <li
        v-for="board in boards"
        :key="board.id"
      ><router-link
        :to="'/boards/' + board.id"
        :title="board.name"
      >{{ board.name }}</router-link></li>
    </ul>
  </section>
</template>

<script setup>
  import {inject, onMounted, ref} from 'vue';
  import AppMainMenu from '../components/AppMainMenu';

  const setContext = inject('setContext');

  const boards = ref([]);
  function fetchBoards () {
    fetch('/api/boards/')
      .then(resp => {
        if (resp.ok) {
          resp.json()
            .then(data => {
              console.debug('data', data);
              boards.value = data;
            });
        } else if (resp.status === 403) {
          window.location = '/account/login';
        } else {
          console.debug('Error fetching boards!', resp);
        }
      });
  }
  onMounted(() => {
    setContext({board: null});
    fetchBoards();
  });
</script>

<style>
  .board-list > ul {
    margin: 0 auto;
    padding: 0;
    min-width: 20em;
    width: max-content;

    list-style: none;
  }
  .board-list > ul > li {
    margin: 2em auto;
    padding: 0;
    background: rgb(255 255 255 / 0.8);
    border-radius: 0.5em;
  }
  .board-list > ul > li + li {
  }
  .board-list a {
    padding: 0.5em 1em;
    display: block;
    color: black;
    font-size: x-large;
    font-weight: bold;
    text-decoration: none;
  }
</style>
