<template>
  <AppModalDialog :header="item.isTemplate ? (item.id ? 'Edit' : 'Create') + ' Template' : null">
    <form class="item-detail" v-on:click="hideTags">
      <header>
        <h1><input
          v-model="item.title"
          placeholder="Item Title"
          required
        ></h1>
        <div class="subheader">
          <input v-if="!item.isTemplate" type="date" v-model="item.date" required>
          <input type="time" v-model="item.time" required>
          <input v-if="item.isTemplate" id="add-daily-checkbox" type="checkbox" v-model="item.add_daily">
          <label v-if="item.isTemplate" for="add-daily-checkbox">Add Daily?</label>
          <select v-model="item.category">
            <option :value="null">(Uncategorized)</option>
            <option
              v-for="category in categories"
              :value="category.id"
              :key="category.id"
            >{{ category.name }}</option>
          </select>
        </div>
      </header>

      <textarea
        v-model="item.description"
        placeholder="Description"
      ></textarea>

      <div class="buttons">
        <button @click.prevent="completeAll">Complete All</button>
      </div>
      <VueDraggableNext
        tag="ul"
        class="task-list"
        :list="item.tasks"
        :sort="true"
        handle=".handle"
      >
        <li v-for="(task, idx) in item.tasks" v-bind:key="idx">
          <div class="task-main">
            <input v-if="!item.isTemplate" type="checkbox" v-model="task.completed">
            <input
              class="task-name-input"
              type="text"
              maxlength="50"
              v-model="task.name"
            >
            <span
              class="fas fa-times-circle"
              v-if="item.tasks.length > 1"
              v-on:click="$emit('delete-task', idx)"
            ></span>
          </div>

          <ul class="tags">
            <li
              v-for="tag in task.tags"
              v-bind:style="{ background: tagColors[tag] }"
              v-bind:key="tag"
            ></li>
          </ul>

          <div class="task-meta">
            <span class="handle fas fa-grip-lines"></span>
            <div>
              <input
                id="units-input"
                type="number"
                min="0" max="100"
                v-model.number="task.units"
              ><label for="units-input"
              ><span class="fas fa-hand-sparkles"></span></label>
            </div>
            <div>
              <button v-on:click.stop.prevent="editTags(task)">
                <span></span>
              </button>
              <span class="fas fa-tags"></span>
              <div class="tag-selector" v-if="editTagsForTask === task">
                <span
                  v-for="tag in tags"
                  v-bind:class="{ inactive: !task.tags.includes(tag.id) }"
                  v-bind:style="{ background: tag.color }"
                  v-bind:key="tag"
                  v-on:click.stop="toggleTag(task, tag.id)"
                >{{ tag.label }}</span>
              </div>
            </div>
            <select v-if="!item.isTemplate" v-model="task.doer">
              <option v-bind:value="undefined">(None)</option>
              <option
                v-for="doer in doers"
                v-bind:value="doer"
                v-bind:key="doer.name"
              >{{ doer.name }}</option>
            </select>
            <span
              class="fas fa-clone"
              v-on:click="$emit('clone-task', idx)"
            ></span>
          </div>
        </li>
      </VueDraggableNext>
      <button
        class="add-task-button"
        v-on:click.prevent="$emit('add-task')"
      >Add Subtask</button>


      <footer class="buttons">
        <button
          :disabled="!valid"
          @click.prevent="$emit('save')"
        >Save {{ item.isTemplate ? 'Template' : 'Item' }}</button>
        <button
          v-if="item.id"
          v-on:click.prevent="$emit('delete')"
        >Delete {{ item.isTemplate ? 'Template' : 'Item' }}</button>
      </footer>
    </form>
  </AppModalDialog>
</template>

<script setup>
  import {computed, ref, toRefs} from 'vue';
  import {VueDraggableNext} from 'vue-draggable-next';
  import AppModalDialog from './AppModalDialog';

  const props = defineProps({
    categories: Object,
    doers: Object,
    item: Object,
    tags: Object,
    tagColors: Object,
  });
  const {item} = toRefs(props);
  const editTagsForTask = ref(null);

  function completeAll () {
    for (const task of item.value.tasks) {
      task.completed = true;
    }
  }

  function editTags (task) {
    if (editTagsForTask.value !== task) {
      editTagsForTask.value = task;
    } else {
      editTagsForTask.value = null;
    }
  }
  function hideTags () {
    editTagsForTask.value = null;
  }
  function toggleTag (task, tag) {
    if (task.tags.includes(tag)) {
      task.tags.splice(task.tags.indexOf(tag), 1);
    } else {
      task.tags.push(tag);
    }
  }

  const valid = computed(() => !!item.value.title);
</script>

<style>
  .item-detail {
    max-width: 40em;
    width: calc(95vw - 3em);
  }

  .item-detail header {
    margin: -0.5em 0 0.5em;
  }
  .item-detail h1 input {
    width: calc(100% - 3em);
    min-width: 17em;
  }
  .item-detail .subheader {
    display: flex;
  }
  .item-detail .subheader label {
    margin-top: 0.5em;
  }
  .item-detail .subheader select {
    margin: auto;
  }

  .item-detail textarea {
    width: 100%;
    height: 4em;
    margin-bottom: 1em;
  }

  .item-detail .buttons {
    display: flex;
    justify-content: space-around;
  }

  .item-detail .task-list {
    margin: 1em 0 0;
    padding: 0;
    list-style-type: none;
  }
  .item-detail .task-list li {
    margin-bottom: 0.2em;
    padding-bottom: 0.2em;
    border-bottom: 1px solid rgb(54 27 10);
  }
  .item-detail .task-main {
    display: flex;
  }
  .item-detail .task-main input[type="checkbox"] {
    margin: 0.2em;
  }
  .item-detail .task-main input[type="text"] {
    flex-grow: 1;
  }
  .item-detail .task-meta {
    display: flex;
    justify-content: space-between;
    min-width: max-content;
    margin: auto;
  }
  .item-detail .task-list .tags {
    display: flex;
    margin: 1px 1.5em;
    padding: 0;
    list-style-type: none;

    border-radius: 0.15em;
    overflow: hidden;
  }
  .item-detail .task-list .tags li {
    flex-grow: 1;
    height: 0.3em;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .item-detail > .tags span.active {
    filter: none;
  }
  .item-detail .task-meta .handle {
    margin: auto 0.2em;
    padding: 0.5em 0;
  }
  #units-input {
    width: 3.5em;
  }
  .item-detail .task-meta label {
    margin-right: 0.2em;
  }
  .item-detail .task-meta button {
    margin: 0;
    padding: 0.5em 0.2em;
    background: rgb(255 255 255);
    border-radius: 0.5em;

  }
  .item-detail .task-meta button {
  }
  .item-detail .task-meta button span {
    display: block;
    height: 1em;
    width: 2em;
    background: linear-gradient(
      90deg,
      rgb(255, 0, 0),
      rgb(255, 63, 0),
      rgb(255, 255, 0),
      rgb(0, 255, 0),
      rgb(0, 255, 255),
      rgb(0, 0, 255),
      rgb(127, 0, 255)
    );
  }
  .item-detail .tag-selector {
    display: flex;
    left: 30%;
    max-width: 65%;
    position: absolute;

    flex-wrap: wrap;
    padding: 0;

    background: rgb(127 127 127 / 80%);
    border-radius: 0.3em;
  }
  .item-detail .tag-selector span {
    margin: 0.5em;
    padding: 0.2em;

    border-radius: 0.2em;
    cursor: pointer;
  }
  .item-detail .tag-selector span.inactive {
    background: rgb(0 0 0 / 50%) !important;
    color: rgb(191 191 191);
  }

  .item-detail .add-task-button {
    display: block;
    margin: 1em auto;
  }
</style>
