import {createRouter, createWebHashHistory} from 'vue-router';

import BoardList from '@/views/BoardList.vue';
import BoardView from '@/views/BoardView.vue';


const routes = [
  {
    path: '/boards',
    component: BoardList,
  },
  {
    path: '/boards/:boardId',
    component: BoardView,
  },
  {
    path: '/:path(.*)',
    redirect: '/boards',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


export default router;
