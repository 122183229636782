<template>
  <AppModalDialog
    class="board-admin"
    :header="'Manage ' + (current ? sections[current].name : 'Board')"
    @close="clickedClose"
  >
    <nav v-if="!current"><ul>
      <li><button @click="$emit('editTemplates')">Templates</button></li>
      <li><button @click="current = 'categories'">Categories</button></li>
      <li><button @click="current = 'tags'">Tags</button></li>
      <li><button @click="current = 'doers'">People</button></li>
    </ul></nav>
    <section v-if="current == 'categories'">
      <ul>
        <li v-for="category in categories" :key="category.id">
          <span @click="editCategory(category)">{{ category.name }} <span class="fas fa-edit"></span></span>
          <button class="fas fa-trash" @click="deleteCategory(category)"></button>
        </li>
      </ul>
      <button @click="addCategory">Add New Category</button>
    </section>
    <section v-if="current == 'tags'">
      <ul>
        <li v-for="tag in tags" :key="tag.id">
          <span @click="editTag(tag)">{{ tag.label }} <span class="fas fa-edit"></span></span>
          <button class="fas fa-trash" @click="deleteTag(tag)"></button>
        </li>
      </ul>
      <button @click="addTag">Add New Tag</button>
    </section>
    <section v-if="current == 'doers'">
      <ul>
        <li v-for="doer in doers" :key="doer.id">
          <span @click="editDoer(doer)">{{ doer.name }} <span class="fas fa-edit"></span></span>
          <button class="fas fa-trash" @click="deleteDoer(doer)"></button>
        </li>
      </ul>
      <button @click="addDoer">Add New Person</button>
    </section>
  </AppModalDialog>
</template>

<script setup>
  import {computed, ref, toRefs, watch} from 'vue';
  import AppModalDialog from './AppModalDialog';

  const props = defineProps({
    categories: Object,
    doers: Object,
    tags: Object,
    templates: Object,
  });
  const {categories, doers, tags, templates} = toRefs(props);
  const emit = defineEmits(['action', 'close', 'editTemplates']);
  function doAction (name, type, arg) {
    emit('action', {name, type, arg});
  }

  // sections
  const sections = computed(() => {
    return {
      templates: {name: 'Templates', items: templates.value},
      categories: {name: 'Categories', items: categories.value},
      tags: {name: 'Tags', items: tags.value},
      doers: {name: 'People', items: doers.value},
    };
  });
  const current = ref(null);
  watch(current, (current) => {
    console.debug('current', sections.value[current]);
  });
  function clickedClose () {
    if (current.value) current.value = null;
    else emit('close');
  }

  // categories
  function deleteCategory (category) {
    if (window.confirm(`Delete category "${ category.name }"?`)) doAction('delete', 'category', category.id);
  }
  function editCategory (category) {
    const update = Object.assign({}, category);
    update.name = window.prompt('Update category name:', update.name);
    if (update.name) doAction('update', 'category', update);
  }
  function addCategory () {
    const name = window.prompt('New category name:');
    if (name) doAction('create', 'category', {name});
  }

  // tags
  function deleteTag (tag) {
    if (window.confirm(`Delete tag "${ tag.label }"?`)) doAction('delete', 'tag', tag.id);
  }
  function editTag (tag) {
    const update = Object.assign({}, tag);
    update.label = window.prompt('Update label for tag:', update.label);
    if (update.label) doAction('update', 'tag', update);
  }
  function addTag () {
    const label = window.prompt('Label for new tag:');
    if (label) doAction('create', 'tag', {label, color: '#FF00FF'});
  }

  // doers
  function deleteDoer (doer) {
    if (window.confirm(`Deactivate "${ doer.name }"?`)) doAction('delete', 'doer', doer.id);
  }
  function editDoer (doer) {
    const update = Object.assign({}, doer);
    update.name = window.prompt(`Update name for ${ doer.name }:`, update.name);
    if (update.name) doAction('update', 'doer', update);
  }
  function addDoer () {
    const name = window.prompt("New person's name:");
    if (name) doAction('create', 'doer', {name});
  }
</script>

<style scoped>
  .board-admin {
    padding: 0 0.5em;
  }
  .board-admin nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .board-admin nav li {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .board-admin nav li button {
    width: 100%;
  }
  .board-admin nav li button:disabled {
    opacity: 0.5;
  }
  .board-admin > section ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .board-admin > section li {
    display: flex;
    margin: 0.5em auto;

    text-align: center;
  }
  .board-admin > section li > span {
    display: flex;
    flex: 100%;
    margin: 0 0.5em;
    padding: 0.5em;

    background: rgb(0 0 0 / 0.5);
    border-radius: 0.5em;
  }
  .board-admin > section li > span > span {
    margin-left: auto;
  }
  .board-admin > section li > button {
    background: transparent;
    border: 0;
  }
  .board-admin > section > button {
    display: block;
    margin: 0.5em auto;
    padding: 0.5em 1em;
    width: 50;

    border-radius: 1em;
    font-size: large;
  }
</style>
