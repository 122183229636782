<template>
  <router-view></router-view>
  <footer>Tabula v{{ VERSION }}</footer>
</template>

<script setup>
  import {provide, ref} from 'vue';

  const VERSION = process.env.VERSION;

  const context = ref({});
  function setContext (newContext) {
    console.debug('setContext', newContext);
    context.value = newContext;
  }
  provide('setContext', setContext);
</script>

<style>
  /* generic styles */
  .fas {
    margin: auto 0 auto 0.2em;
  }
  input {
    min-height: 2em;
  }
  button {
    min-height: 2.5em;
    margin: 0.5em 0;
    padding: 0.5em;
  }

  /* app styles */
  body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 100em;
    margin: 0 auto;
    padding: 0;

    background: rgb(127 63 0);
    color: rgb(255 255 255);
  }
  #app {
    display: contents;
  }
  #app > section {
    flex: 100%;
  }
  h1 {
    margin: 0.5em 1em;
  }
  #app > footer {
    margin: 0 auto;
    width: max-content;
  }
</style>
