<template>
  <section ref="container" class="item-list">
    <h2>{{ title }}</h2>
    <ol>
      <BoardItemListItem
        v-for="item in items"
        v-bind:key="item.id"
        v-bind:item="item"
        v-on:open-item="$emit('open-item', item)"
      ></BoardItemListItem>
    </ol>
  </section>
</template>

<script setup>
  import {ref} from 'vue';
  import BoardItemListItem from './BoardItemListItem.vue';

  defineProps({title: String, items: Array});
  const container = ref(null);
  defineExpose({container});
</script>

<style>
  .item-list h2 {
    margin: 0 0 0.5em;
  }
  .item-list ol {
    flex: 100%;
    margin: 0;
    padding: 0;

    overflow-y: auto;
    list-style: none;
  }
  .item-list > ol > li.label {
    position: relative;
    top: -0.6em;
    text-align: center;
    border-bottom: 1px solid;
    font-size: 0.9em;
  }
  .item-list > ol > li.label span {
    position: relative;
    top: 0.6em;
    padding: 0 2px;
    background: rgb(175 105 50);
  }
  .item-list > ol > li.item {
    margin: 0;
    padding: 0 0.3em 0.3em;

    background: rgb(255 255 255 / 80%);
    color: rgb(54 27 10);
    border-radius: 4px;
    overflow: hidden;
  }
  .item-list > ol > li + li {
    margin: 2px 0 0;
  }
  .item-list > ol > li.item + li.item {
    margin-top: 0.5em;
  }
  .item-list header {
    display: flex;
    margin: 0.2em 0;
    align-items: baseline;
  }
  .item-list .tags {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .item-list .tags li {
    height: 0.3em;
    flex-grow: 1;
  }
  .item-list .body {
    margin: 0.5em 0 0;
    display: flex;
  }
  .item-list h3 {
    margin: 0;
    flex-grow: 1;
  }
  .item-list .body > * {
    margin: 0 0.2em;
    padding: 0.1em 0.3em;
    border-radius: 0.2em;

    background: rgb(54 27 10 / 80%);
    color: rgb(255 255 255 / 80%);
  }
  .item-list .units {
    min-width: max-content;
  }
  .item-list .tasks-count {
    margin-left: 0;
    margin-right: auto;
    background: inherit;
    color: inherit;
  }
</style>
